import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Gallery from '~/components/Gallery';

import Layout from '../components/Layout';
import './equipo-miembro.scss';

export const MemberPageTemplate = ({
  member,
  projects = null,
  bodyIsMarkdown = false,
}) => {
  const galleryItems = [...(member?.videos ?? []), ...(member?.gallery ?? [])];
  return (
    <>
      <article className="member-detail">
        <div className="member-detail__img-container">
          <img className="member-detail__img" src={member.image} alt="" />
        </div>
        <div className="member-detail__info">
          <h1 className="member-detail__name">{member.name}</h1>
          <p className="member-detail__description">{member.description}</p>
        </div>
      </article>
      {!!galleryItems.length && <Gallery items={galleryItems} />}
    </>
  );
};

const MemberPage = ({ data }) => {
  const { markdownRemark: page } = data;
  const { frontmatter } = page;
  const member = {
    name: frontmatter.name,
    description: frontmatter.description,
    image: frontmatter.image,
    gallery: frontmatter.gallery ?? [],
    videos: frontmatter.videos?.filter(v => !v.hidden) ?? [],
    id: frontmatter.id,
  };

  return (
    <Layout footerData={data.footerData} navbarData={data.navbarData}>
      <Helmet>
        <meta name="title" content={`${member.name} :: Mitá`} />
        <meta name="description" content={`${member.name} :: Mitá`} />
        <title>{member.name} :: Mitá</title>
      </Helmet>
      <MemberPageTemplate member={member} />
    </Layout>
  );
};

MemberPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MemberPage;

export const memberPageQuery = graphql`
  query MemberPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        name
        description
        image
        gallery
        videos {
          vimeoId
          label
          preview
          hidden
        }
        id
      }
    }
    ...LayoutFragment
  }
`;
