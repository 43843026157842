import React from 'react';

export default function PlayButton(props) {
  return (
    <button className="play-btn" {...props}>
      <svg viewBox="0 0 34 34">
        <g>
          <path
            d="M17,0 C26.3888407,0 34,7.61115925 34,17 C34,26.3888407 26.3888407,34 17,34 C7.61115925,34 0,26.3888407 0,17 C0,7.61115925 7.61115925,0 17,0 Z M17,2 C8.71572875,2 2,8.71572875 2,17 C2,25.2842712 8.71572875,32 17,32 C25.2842712,32 32,25.2842712 32,17 C32,8.71572875 25.2842712,2 17,2 Z"
            fill="currentColor"
          />
          <path
            d="M17,32 C25.2842712,32 32,25.2842712 32,17 C32,8.71572875 25.2842712,2 17,2 C8.71572875,2 2,8.71572875 2,17 C2,25.2842712 8.71572875,32 17,32 Z"
            fill="#596066"
            opacity="0.5"
          />
          <polygon fill="currentColor" points="12 11 12 23 24 17" />
        </g>
      </svg>
    </button>
  );
}
