import VimeoPlayer from '@vimeo/player';
import React from 'react';

import './VideoWrapper.scss';

const VideoWrapper = ({ id }) => {
  const videoRef = React.useRef();

  React.useEffect(() => {
    var options = {
      id,
      // responsive: true,
    };
    var player = new VimeoPlayer(videoRef.current, options);

    player.play();
  }, [id]);

  return <div className="video-wrapper" ref={videoRef} />;
};

export default VideoWrapper;
