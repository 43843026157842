import { DialogOverlay, DialogContent } from '@reach/dialog';
import PropTypes from 'prop-types';
import React from 'react';
import '@reach/dialog/styles.css';
import { useSwipeable } from 'react-swipeable';
import { useKeyPressEvent } from 'react-use';

import './Gallery.scss';
import PlayButton from '~/components/PlayButton';
import VideoWrapper from '~/components/VideoWrapper';

const Gallery = ({ items }) => {
  const [current, setCurrent] = React.useState(null);
  const back = () => setCurrent(Math.max(0, current - 1));
  const next = () => setCurrent(Math.min(items.length - 1, current + 1));
  const handlers = useSwipeable({ onSwipedLeft: next, onSwipedRight: back });

  useKeyPressEvent('ArrowLeft', back);
  useKeyPressEvent('ArrowRight', next);

  const showLeftButton = current > 0;
  const showRightButton = current < items.length - 1;

  const TRANSFORMATION = 'w_500,ar_1:1,c_fill,g_auto';

  const getThumbnailUrl = url => {
    return url.replace('upload/', `upload/${TRANSFORMATION}/`);
  };
  return (
    <div className="project-gallery">
      <div className="project-gallery__items">
        {items?.length &&
          items.map((item, idx) => (
            <div key={item?.preview || item} className="project-gallery__item">
              {item.vimeoId && <PlayButton onClick={() => setCurrent(idx)} />}
              <img
                src={item?.preview || getThumbnailUrl(item)}
                className="project-gallery__image"
                alt=""
                onClick={() => setCurrent(idx)}
              />
            </div>
          ))}
      </div>
      <DialogOverlay
        isOpen={current !== null}
        onDismiss={() => setCurrent(null)}
        allowPinchZoom
        className="project-gallery__modal-overlay"
      >
        <DialogContent
          className="project-gallery__modal"
          aria-label="Content preview"
        >
          {showLeftButton && (
            <button
              className="project-gallery__modal__arrow project-gallery__modal__arrow-left"
              onClick={back}
            >
              ‹
            </button>
          )}
          <button className="close-btn" onClick={() => setCurrent(null)}>
            <span aria-hidden>×</span>
          </button>
          {items[current]?.vimeoId ? (
            <VideoWrapper key={current} id={items[current].vimeoId} />
          ) : (
            <img {...handlers} src={items[current]} alt="" />
          )}
          {showRightButton && (
            <button
              className="project-gallery__modal__arrow project-gallery__modal__arrow--right"
              onClick={next}
            >
              ›
            </button>
          )}
        </DialogContent>
      </DialogOverlay>
    </div>
  );
};

Gallery.propTypes = {
  items: PropTypes.any,
};

export default Gallery;
